/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Icon } from "design-react-kit";
import { ActaAuthContext } from "pa_kit/auth";
import { MenuClass } from "pa_kit/apps/menus/models/menu";
import parse from 'html-react-parser';
import CookieConsent from "react-cookie-consent";
import authApiService from "pa_kit/services/api";
import { getCookie } from "pa_kit/services/manage_cookies";

const FooterContent = () => {

    const auth = useContext(ActaAuthContext);
    const actagov_config = auth.organization.active_licenses?.services?.actagov;

    const organization = auth.organization;
    const websiteSettings = auth.websiteSettings;

    const auth_config = organization.active_licenses?.services?.auth;
    const contact_links = auth.websiteSettings.contact_links?.links;

    const [footerMenu, setFooterMenu] = useState();
    const [footerContent, setFooterContent] = useState();

    const [cookieConsent, setCookieConsent] = useState(getCookie('CookieConsent'));

    const getFooterMenu = () => {
        if(!websiteSettings) return;
        setFooterMenu(new MenuClass(websiteSettings.footer_menu_data));
    }

    const getFooterContent = () => {
        if(!websiteSettings) return;
        setFooterContent(parse(websiteSettings.footer_content) ?? "Error");
    }

    useEffect(() => {
        getFooterContent();
        getFooterMenu();
    }, [websiteSettings]); 

    const acceptAllCookies = async() => {

        const formData = {
            source: 'cookie',
            necessary: true,
            statistics: true
        };

        setCookieConsent(true);

        try {
            await authApiService.createGDPRRegistry(formData);
        } catch(err) {
            console.log(err);
        }

    }

    const acceptOnlyNecessaryCookies = async() => {

        const formData = {
            source: 'cookie',
            necessary: true,
            statistics: false
        };

        setCookieConsent(true);

        try {
            await authApiService.createGDPRRegistry(formData);
        } catch(err) {
            console.log(err);
        }
        
    }   

    return <>
        <CookieConsent 
            disableButtonStyles={true} 
            containerClasses="cookie-consent-bar" 
            flipButtons 
            enableDeclineButton 
            buttonText="Si, acconsento" 
            declineButtonText="Solo Tecnici" 
            buttonClasses="btn btn-primary btn-sm m-1" 
            declineButtonClasses="btn btn-primary btn-sm m-1"
            onAccept={() => acceptAllCookies()}
            onDecline={() => acceptOnlyNecessaryCookies()}
        >
            Visitando il nostro sito Web l'utente accetta che stiamo usando i cookie per assicurare la migliore esperienza di navigazione. <a href={actagov_config?.frontend_url + 'informativa-privacy'} title="Informativa Privacy" style={{color: '#fff'}}>Visualizza Informativa Privacy</a>
        </CookieConsent>
        <footer className="it-footer">
            <div className="it-footer-main">
                <Container>
                <section>
                    <Row className="clearfix">
                    <Col sm={12}>
                        <div className="it-brand-wrapper">
                            <a className="" href={actagov_config?.frontend_url}>                            
                                {organization.logo_footer
                                ? <img src={auth_config?.backend_url + organization.logo_footer} alt='Logo Footer' title={organization.name} width='82' height='82' />
                                : <Icon icon="it-pa" />}

                                <div className="it-brand-text">
                                    <h2>{organization.name || 'Comune Alfa'}</h2>
                                    <h3 className="d-none d-md-block">{organization.subname || 'Uno dei tanti Comuni d\'Italia'}</h3>
                                </div>
                            </a>
                        </div>
                    </Col>
                    </Row>
                </section>
                <section>
                    {footerContent}
                </section>
                <section className="py-4 border-white border-top">
                    <Row>
                    <Col className="pb-2" lg={3} md={3}>
                        <p>
                            <a href={organization.amm_trasp_link || '#'} title="Vai alla pagina: Amministrazione">Amministrazione trasparente</a>
                        </p>
                        <p>I dati personali pubblicati sono riutilizzabili solo alle condizioni previste dalla direttiva comunitaria 2003/98/CE e dal d.lgs. 36/2006</p>
                    </Col>
                    <Col className="pb-2" lg={3} md={3}>
                        <p>
                            <a href={actagov_config?.frontend_url + "contatti"} title="Vai alla pagina: Contatti">Contatti</a>
                        </p>
                        <div>
                            <strong>{organization.name || 'Nome del Comune'}</strong>
                            <br />
                            {organization.address || 'Via Roma 0 - 00000 Lorem Ipsum Codice fiscale / P. IVA: 000000000'}
                            <br/>
                            {organization.piva && organization.piva.map((partitaIva)=>
                                <p className="mb-0" key={partitaIva.piva}>{partitaIva.name}: {partitaIva.piva}</p>
                            )}
                            {organization.fiscal_code && organization.fiscal_code.map((cf)=>
                                <p className="mb-0" key={cf.cf}>{cf.name}: {cf.cf}</p>
                            )}
                        </div>
                    </Col>
                    <Col className="pb-2" lg={3} md={3}>
                        <p>&nbsp;</p>
                        <div>
                            {organization.email && organization.email.map((emailAddress)=>
                                <p className="mb-0" key={emailAddress.email}>{emailAddress.name}: {emailAddress.email}</p>
                            )}
                            <br/>
                            {organization.phone && organization.phone.map((tel)=>
                                <p className="mb-0" key={tel.number}>{tel.name}: {tel.number}</p>
                            )}
                        </div>
                    </Col>
                    <Col className="pb-2" lg={3} md={3}>
                        <div className="link-list-wrapper">
                            <ul className="footer-list link-list clearfix footer-help-link">
                                <li>
                                    <a title="Leggi le FAQ" href={actagov_config?.frontend_url + "domande-frequenti/"} data-element="faq">Leggi le FAQ</a>
                                </li>
                                <li>
                                    <a title="Segnalazione disservizio" href={(contact_links && contact_links.report) ? contact_links.report.link : '#'} data-element="report-inefficiency">Segnalazione disservizio</a>
                                </li>
                                <li>
                                    <a title="Dichiarazione di accessibilità" href={organization.accessibility_link} target="_blank" rel="noreferrer" data-element="accessibility-link">Dichiarazione di accessibilità</a>
                                </li>
                            </ul>
                        </div>
                        <p><strong>SEGUICI SU</strong></p>
                        <ul className="list-inline text-start social">                           
                            {organization.social && organization.social.map((social)=>
                                <li key={social.name} className="list-inline-item">
                                    <a aria-label={social.name} href={social.link} target="_blank" rel="noreferrer"><Icon icon={social.icon} className="align-top" color="white" size="sm" /></a>                                
                                </li>
                            )} 
                        </ul>
                    </Col>
                    </Row>
                </section>
                </Container>
            </div>
            <div className={cookieConsent ? "it-footer-small-prints clearfix" : "it-footer-small-prints clearfix no-cookie-consent"}>
                <Container>
                    <Row>
                        <Col lg="9">
                            <h3 className="sr-only">Sezione Link Utili</h3>
                            <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                                {footerMenu && footerMenu.content.items.map((footerItem) => {
                                    let footerItemDataElement;

                                    switch(footerItem.name) {

                                        case "Privacy Policy":
                                        case "Informativa Privacy":
                                            footerItemDataElement = 'privacy-policy-link';
                                            break;

                                        case "Note Legali":
                                            footerItemDataElement = 'legal-notes';
                                            break;

                                        default:
                                            footerItemDataElement = '';
                                    }

                                    return(
                                        <li key={footerItem.name} className="list-inline-item">
                                            <a href={footerItem.url} title={footerItem.name} data-element={footerItemDataElement}>{footerItem.name}</a>
                                        </li>
                                    )
                                })}                        
                            </ul>
                        </Col>
                        <Col lg="3">
                            <p className="text-end mb-0 text-white poweredby">Powered by <img src={auth_config?.backend_url + 'media/organization/logo_actainfo.webp'} width="20" height="20" alt="Logo Actainfo" title="Actainfo" /> <a href="https://www.actainfo.it" target="_blank" rel="noreferrer"><strong>ACTAINFO</strong></a></p>
                        </Col>
                    </Row>
                
                </Container>
            </div>
        </footer>
    </>;
}

const EmbeddedFooter = () => {

    const auth = useContext(ActaAuthContext);
    const websiteSettings = auth.websiteSettings;
    
    const auth_config = auth.organization.active_licenses?.services?.auth;

    const [htmlContent, setHtmlContent] = useState('');

    const fetchData = async() => {
        try {
            const html = await authApiService.getEmbeddedFooter(websiteSettings.embedded_footer_url);

            // Create a temporary DOM element to parse the HTML
            const tempElement = document.createElement('div');
            tempElement.innerHTML = html;

            // Remove link, style, and script tags
            const tagsToRemove = ['link', 'style', 'scripts', 'script'];
            tagsToRemove.forEach(tagName => {
                const tags = tempElement.querySelectorAll(tagName);
                tags.forEach(tag => tag.remove());
            });

            // Set the modified HTML content
            setHtmlContent(tempElement.innerHTML);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    useEffect(() => {
        fetchData();
    }, []);

    return <>
        <div className="embedded-footer-wrapper" dangerouslySetInnerHTML={{ __html: htmlContent }} />
        <div className="it-footer-small-prints clearfix">
                <Container>
                    <Row>
                        <Col lg="12">
                            <p className="text-end mb-0 text-white poweredby">Powered by <img src={auth_config?.backend_url + 'media/organization/logo_actainfo.webp'} width="20" height="20" alt="Logo Actainfo" title="Actainfo" /> <a href="https://www.actainfo.it" target="_blank" rel="noreferrer"><strong>ACTAINFO</strong></a></p>
                        </Col>
                    </Row>
                
                </Container>
            </div>
    </>;
        


}

const Footer = () => {

    const auth = useContext(ActaAuthContext);
    const websiteSettings = auth.websiteSettings;

    if (websiteSettings.embedded_footer_url) return <EmbeddedFooter />;

    return <FooterContent />

}

export default Footer;